import { initialize, LDClient, LDUser } from "launchdarkly-js-client-sdk";

export interface Bootstrap {
  featureFlagEnvKey: string;
  featureFlagEnvName: string;
  featureFlags?: {
    environment: {
      key: string;
      name: string;
    };
  };
}

export let listeners: (() => void)[] = [];

export let ffClient: LDClient;

export let environmentKey: string;

export let environmentName: string;

export const addChangeListener = (listener: () => unknown): void => {
  if (!listeners.includes(listener)) {
    listeners.push(listener);
  }
};

export const removeChangeListener = (listener: () => unknown): void => {
  if (listeners.includes(listener)) {
    listeners = listeners.filter((listenerValue) => listenerValue !== listener);
  }
};

export const changeUser = function (user: LDUser): void {
  if (!ffClient) return;
  const _user = {
    ...user,
    custom: {
      ...user.custom,
      environment: environmentName,
    },
  };

  ffClient.identify(_user, undefined, () => {
    listeners.forEach((listener: () => unknown) => listener());
  });
};

export const bootstrap = async (): Promise<Bootstrap> => {
  const response = await fetch("/web/common/bootstrap");
  return await response.json();
};

export const envKey = (bootstrap: Bootstrap): string =>
  bootstrap.featureFlagEnvKey;

export const envName = (bootstrap: Bootstrap): string =>
  bootstrap.featureFlagEnvName;

export const initializeFeatureFlags = async () => {
  try {
    const bootstrapResponse = await bootstrap();
    environmentKey = envKey(bootstrapResponse);
    environmentName = envName(bootstrapResponse);
  } catch (error) {
    console.error(
      "There was a problem initializing feature flag variables",
      error
    );

    return false;
  }

  ffClient = initialize(
      environmentKey,
      {
          key: 'anonymous',
          anonymous: true,
          custom: {
              environment: environmentName,
          },
      },
      {
          allAttributesPrivate: true,
      }
  );

  ffClient.on("change", () => {
    console.log("Flag change requested");
    listeners.forEach((listener: () => unknown) => listener());
  });

  try {
    await ffClient.waitForInitialization();
    console.log("It's now safe to request feature flags");

    return true;
  } catch (error) {
    console.error("There was a problem initializing feature flags", error);

    return false;
  }
};

export const reset = function (_envKey?: string, _envName?: string): void {
  ffClient = initialize(_envKey ?? environmentKey, {
    key: "anonymous",
  });
  listeners = [];
  environmentName = _envName || environmentKey;
};

export const isReady = initializeFeatureFlags();
