const SERVICE_NAME = "web";

enum GROUP {
  DEV = "dev",
  CS = "cs",
  OPS = "ops",
  PRD = "prd",
}

const flagKey = (grp: string) => (key: string) =>
  `${SERVICE_NAME}-${grp}-${key}`;

export const devKey = flagKey(GROUP.DEV);
export const csKey = flagKey(GROUP.CS);
export const opsKey = flagKey(GROUP.OPS);
export const prdKey = flagKey(GROUP.PRD);
